import React, { useEffect, useState } from "react";

function App() {

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = +new Date(2021, 7, 27, 12, 0, 0) - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        }
    
        return timeLeft;
      };
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
      const [year] = useState(new Date().getFullYear());
    
      useEffect(() => {
        setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
      });
    
      const timerComponents = [];
    
      Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          return;
        }
    
        timerComponents.push(
            <div class="w-24 m-1 p-2 bg-white text-yellow-500 rounded-lg">
                <div class="font-mono leading-none">{timeLeft[interval]}</div>
                <div class="font-mono uppercase text-sm leading-none">{interval}{" "}</div>
            </div>
        );
      });

  return (
    <div class="min-w-screen min-h-screen bg-yellow-500 flex items-center justify-center px-5 py-5">
        <div class="text-yellow-100">
            <h1 class="text-3xl text-center mb-3 font-extralight">Will it be CIO-SP4ever?</h1>
            <div class="text-6xl text-center flex flex-col sm:flex-row w-full items-center justify-center">
                {timerComponents.length ? timerComponents : <span>No! The RFP has closed!</span>}
            </div>
        </div>
    </div>
  );
}

export default App;
